$box-background-color: #1d1d1d;

html {
    scroll-behavior: smooth;
}

.anchor {
    position:absolute;
    padding-top:100px;
    margin-top:-150px;    
}

header.main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    background: transparent; 
    color:black; 
    font-weight: 500;
    .top {
        background: transparent;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        padding-top: 10px;
        padding-bottom: 10px;        
        .inner {
            display: flex;
            justify-content: space-between;

            .company-info {
                display: flex;
                flex-wrap: wrap;
                .email {
                    margin-right: 13px;
                }
            }
            .social {

            }
        }
    } 
    nav {
        padding: .5rem 1rem;
        .inner {
            padding-top: 5px;
            display: flex;
            justify-content: space-between;
            .logo-container {
                max-width:150px;
                img {
                    width:100%;
                }
            }
            .nav-links {
                ul {
                    padding-top:5px;
                    li {
                        display: inline-block;
                        padding-left: 15px;
                        vertical-align: middle;
                        .logo-icon {
                            line-height: 0; 
                            img {
                                width:100%;
                            }
                        }
                        a{
                            color: black;
                            font-weight: 500;
                            &.blk {
                                color: black;
                            }
                        }
                    }
                }
            }
        }
        &.switch-nav {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            padding: 5px 0;
            background-color: #ffffff !important;
            box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        }        
    }
}

section.content {

    section.main-image {
        background-image: url(../img/hero1.jpg);
        height: 455px;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
        position: relative;      
        span.overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // background-color: rgba(0, 0, 0, 0.3);
            // background-image: url(../img/dot.png);          
        }
        .top-section-info {
            position: relative;
            color: White;
            z-index: 20;
            padding-top: 200px;
            margin-bottom: 90px;
            text-align: center;            
        }

    }

}

section.vert-doc {
    background-color:black;
    padding-top: 20px;
    padding-bottom: 20px;
    .inner {
        text-align:center;
        .pdf-container {
            margin-left: auto;
            margin-right: auto;           
            .pdf-block {
                display:inline-block;
                .title {
                    display: inline-block;
                    text-align:left;
                    width: 202px;
                    span { 
                        font-size:10px;

                    }
                }
                margin-top:10px;
                margin-bottom: 10px;
                width: 313px;
                padding: 5px;
                border-radius:2px;
                background-color: $box-background-color;
                color: white;
                .download-btn-container {
                    @media(min-width: 550px) {
                        width:91px;
                    }
                    display: inline-block;
                    text-align: right;
                    font-size: 13px;
                    i.fa-download {
                        color: #7f6b7b;
                    }
                    .download-btn {
                        color: #8e7894;
    
                    }                
                }
                @media(max-width:550px) {
                    width:100%;
                    text-align: center;
                }
            }
        }

    }
}

section.hor-doc {
    border-top:1px solid #8e7894;
    border-bottom:1px solid #8e7894;
    background-color: black;
    padding-top: 35px;
    padding-bottom: 35px;
    .pdf-container {
        .pdf-box {
            max-width:250px;
            margin-left:auto;
            margin-right:auto;
            background-color: $box-background-color;
            padding:15px 5px;
            text-align: center;
            margin-bottom: 10px;
            .title {
                color: white;
                font-size: 12px;
                p {
                    margin-bottom:10px;
                }
            }
            .img-box {
                padding:5px;
                margin-bottom: 5px;;
            }
          
            img {
                width: 100%;
                max-width: 150px;                 
            }
            .download-button {
                a {
                    background-color: #7c4889;
                    padding: 5px 12px;
                    font-size:12px;
                    border-radius: 2px;
                    color: white;
                    text-decoration: none;
                }
            }
            @media(max-width: 768px) {
                margin-bottom: 20px;
            }

        }
    }


}

section.audio-narration {
    background-color: black;
    color: white;
    text-align: center;
    .video-container {
        padding-top: 30px;
        .video-box {
            display:inline-block;
            border: 2px solid #7c4889;
            border-radius: 3px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 2px;
        }
    }
}

.contact-modal {

    textarea {
        width:100%;
    }
    .feedback-message {
        .message-sent {
            
            p {
                font-size:13px;
                margin-bottom: 5px;
                color: #28cc28;
            }
        }
    }



    .modal-content {
        background-color: rgba(0, 0, 0, 0.63);
        color: white;
        border: 1px solid rgb(101, 85, 104);

        .modal-header { 
            border-bottom: 1px solid #67576b;
            #xBtn {
                color: white;
            }
        }

        .modal-body {
            padding-top: 4px;
        }

        .modal-footer { 
            border-top: 1px solid #67576b;
        } 

        .btn-primary {
            color: #fff;
            background-color: #7c4889;
            border-color: #7c4889;
        }

        .btn-primary:hover {
            color: #fff;
            background-color: #0a0909;
            border-color: #7c4889;;
        }

        .btn-primary.focus, .btn-primary:focus {
            color: #fff;
            background-color: #0a0909;
            border-color: #7c4889;;
            box-shadow: 0 0 0 0.2rem rgba(186, 38, 255, 0.212);
        }

    }

   
}

hr {
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid #7e6a83;  
}

footer {
    text-align: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: black;
}